(function() {
    var handleWizardTabClick = function() {
        var $obj = $(this);
        var tabId = $obj.attr("class").match(/tab[a-z|A-Z]*/);
        if(tabId) {
            PF("wizard").loadStep(tabId);
        }
    };
    $(document).on("click", ".ui-wizard .ui-wizard-step-titles .ui-wizard-step-title", handleWizardTabClick);

})();
